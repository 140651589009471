var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur } },
    [
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { md: "12" } }, [
            _c(
              "h1",
              {
                class: [
                  `${!_vm.$screen.sm ? "main-page-title" : ""}`,
                  ,
                  { "is-pwa": _vm.$isPwa() },
                ],
              },
              [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(_vm.FormMSG(235, "Projects")) +
                    "\n\t\t\t"
                ),
              ]
            ),
          ]),
          _c(
            "b-col",
            { attrs: { cols: "12", xl: "12" } },
            [
              _c(
                "transition",
                { attrs: { name: "slide" } },
                [
                  _c(
                    "b-card",
                    [
                      _c(
                        "b-row",
                        [
                          _vm.$screen.width >= 992
                            ? _c(
                                "b-col",
                                { staticClass: "my-1", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "mb-0",
                                      attrs: {
                                        label: _vm.FormMSG(10, "Filter"),
                                        "label-cols": "3",
                                        "label-align-sm": "left",
                                        "label-size": "sm",
                                        "label-for": "filterInput",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        { attrs: { size: "sm" } },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              type: "search",
                                              id: "filterInput",
                                              placeholder: _vm.FormMSG(
                                                20,
                                                "Type to Search"
                                              ),
                                            },
                                            model: {
                                              value: _vm.filter,
                                              callback: function ($$v) {
                                                _vm.filter = $$v
                                              },
                                              expression: "filter",
                                            },
                                          }),
                                          _c(
                                            "b-input-group-append",
                                            [
                                              _c(
                                                "b-button",
                                                {
                                                  attrs: {
                                                    disabled: !_vm.filter,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.filter = ""
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "fa fa-times",
                                                    attrs: {
                                                      "aria-hidden": "true",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.$screen.width < 992
                        ? _c("CardListBuilder", {
                            attrs: {
                              items: _vm.Projects,
                              fields: _vm.projectFields,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "actions",
                                  fn: function (data) {
                                    return [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass:
                                            "btn bg-transparent border-0",
                                          attrs: {
                                            variant: "primary",
                                            size: "sm",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.rowClicked(data.item)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            _vm.getLucideIcon(
                                              _vm.ICONS.EYE.name
                                            ),
                                            {
                                              tag: "component",
                                              attrs: {
                                                color: "#225CBD",
                                                size: 20,
                                              },
                                            }
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              677025866
                            ),
                          })
                        : _vm._e(),
                      _vm.$screen.width >= 992
                        ? _c("b-table", {
                            ref: "myTable",
                            staticStyle: { "text-align": "left" },
                            attrs: {
                              hover: _vm.hover,
                              responsive: "lg",
                              "sticky-header": "700px",
                              selectable: "",
                              selectedVariant: _vm.selectedColor,
                              "select-mode": _vm.selectMode,
                              items: _vm.Projects,
                              fields: _vm.projectFields,
                              "current-page": _vm.currentPage,
                              filter: _vm.filter,
                              "per-page": _vm.perPage,
                              bordered: "",
                              small: "",
                              "head-variant": _vm.hv,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "cell(view)",
                                  fn: function (data) {
                                    return [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass:
                                            "btn bg-transparent border-0",
                                          attrs: {
                                            variant: "primary",
                                            size: "sm",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.rowClicked(data.item)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            _vm.getLucideIcon(
                                              _vm.ICONS.EYE.name
                                            ),
                                            {
                                              tag: "component",
                                              attrs: {
                                                color: "#225CBD",
                                                size: 20,
                                              },
                                            }
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1155045229
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }