<style scoped>
.card-body >>> table > tbody > tr > td {
	cursor: pointer;
}

input[type='texte'] {
	width: 100%;
	padding: 12px 20px;
	margin: 4px 0;
	box-sizing: border-box;
	border: none;
	background-color: white;
	color: white;
}
</style>

<template>
	<errorContainer :error="erreur">
		<b-row>
			<b-col md="12">
				<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, , { 'is-pwa': $isPwa() }]">
					{{ FormMSG(235, 'Projects') }}
				</h1>
			</b-col>
			<b-col cols="12" xl="12">
				<transition name="slide">
					<b-card>
						<b-row>
							<b-col cols="12" class="my-1" v-if="$screen.width >= 992">
								<b-form-group
									:label="FormMSG(10, 'Filter')"
									label-cols="3"
									label-align-sm="left"
									label-size="sm"
									label-for="filterInput"
									class="mb-0"
								>
									<b-input-group size="sm">
										<b-form-input
											v-model="filter"
											type="search"
											id="filterInput"
											:placeholder="FormMSG(20, 'Type to Search')"
										></b-form-input>
										<b-input-group-append>
											<b-button :disabled="!filter" @click="filter = ''">
												<i class="fa fa-times" aria-hidden="true"></i>
											</b-button>
										</b-input-group-append>
									</b-input-group>
								</b-form-group>
							</b-col>
						</b-row>
						<CardListBuilder v-if="$screen.width < 992" :items="Projects" :fields="projectFields">
							<template slot="actions" slot-scope="data">
								<b-button variant="primary" size="sm" @click="rowClicked(data.item)" class="btn bg-transparent border-0">
									<component :is="getLucideIcon(ICONS.EYE.name)" color="#225CBD" :size="20" />
								</b-button>
							</template>
						</CardListBuilder>
						<b-table
							v-if="$screen.width >= 992"
							:hover="hover"
							responsive="lg"
							ref="myTable"
							sticky-header="700px"
							selectable
							:selectedVariant="selectedColor"
							:select-mode="selectMode"
							:items="Projects"
							style="text-align: left"
							:fields="projectFields"
							:current-page="currentPage"
							:filter="filter"
							:per-page="perPage"
							bordered
							small
							:head-variant="hv"
						>
							<!-- <template slot="view" slot-scope="data"> -->
							<template v-slot:cell(view)="data">
								<b-button variant="primary" size="sm" @click="rowClicked(data.item)" class="btn bg-transparent border-0">
									<component :is="getLucideIcon(ICONS.EYE.name)" color="#225CBD" :size="20" />
								</b-button>
							</template>
						</b-table>
					</b-card>
				</transition>
			</b-col>
		</b-row>
	</errorContainer>
</template>

<script>
import gql from 'graphql-tag';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { getProjects } from '@/cruds/project.crud'

export default {
	name: 'Projects',
	mixins: [languageMessages, globalMixin],
	props: {
		hover: {
			type: Boolean,
			default: true
		},
		striped: {
			type: Boolean,
			default: true
		},
		bordered: {
			type: Boolean,
			default: false
		},
		small: {
			type: Boolean,
			default: false
		},
		fixed: {
			type: Boolean,
			default: false
		}
	},
	data: () => {
		return {
			hv: 'dark',
			erreur: {},
			Projects: [],
			selectMode: 'single',
			selectedColor: 'primary',
			currentPage: 1,
			filter: '',
			perPage: 0
		};
	},
	computed: {
		projectFields() {
			return [
				{
					key: 'id',
					label: this.FormMSG(100, 'id'),
					sortable: true
				},
				{
					key: 'license.companyName',
					label: this.FormMSG(105, 'License company'),

					sortable: true
				},
				{
					key: 'production',
					label: this.FormMSG(101, 'Production company'),

					sortable: true
				},
				{
					key: 'titre',
					label: this.FormMSG(104, 'Title'),
					sortable: true
				},
				{
					key: 'realisateur',
					label: this.FormMSG(102, 'Director'),
					sortable: true
				},
				{
					key: 'view',
					label: this.FormMSG(103, 'Details'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				}
			];
		}
	},

	methods: {
		getRowCount(items) {
			console.log(items);
			return items.length;
		},
		projectLink(id) {
			return `/projects/${id.toString()}`;
		},
		rowClicked(item) {
			const projectLink = this.projectLink(item.id);
			this.$router.push({ path: projectLink });
		}
	},
	async created() {
		try {
			this.Projects = await getProjects();
		} catch (error) {
			this.error = error
		}
	}
};
</script>

<style scoped>
.card-body >>> table > tbody > tr > td {
	cursor: pointer;
}
</style>
